import { render, staticRenderFns } from "./Purpose.vue?vue&type=template&id=16469f95&scoped=true&"
import script from "./Purpose.vue?vue&type=script&lang=ts&"
export * from "./Purpose.vue?vue&type=script&lang=ts&"
import style0 from "./Purpose.vue?vue&type=style&index=0&id=16469f95&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16469f95",
  null
  
)

export default component.exports